/**
 * Imports
 */
import $ from 'jquery';
import Swiper, { Navigation, Pagination, Controller } from 'swiper';
import 'magnific-popup';
import 'foundation-sites';
import '@/Styles/main.scss';

/**
 * Configuration
 */
window.jQuery = $;
window.$ = $;
Foundation.addToJquery($);

/**
 * Variables
 */
const delayInstant = 0,
    delayFast = 100,
    delayDefault = 300,
    delaySlow = 1000,
    // NOTE: This selector is based on jQuery UI's :tabbable selector at https://api.jqueryui.com/tabbable-selector/
    focusableSelector = 'input:not([disabled]):not([tabindex^="-"]), select:not([disabled]):not([tabindex^="-"]), textarea:not([disabled]):not([tabindex^="-"]), button:not([disabled]):not([tabindex^="-"]), object:not([disabled]):not([tabindex^="-"]), a[href], area[href], [tabindex]:not([tabindex^="-"])',
    nonNavSelector = 'main, footer, #alerts',
    $win = $(window),
    $body = $('body');
    
let $nonNavItems,
    $headerEntry,
    $navSecondary,
    $dropdownButtons,
    $dropdownHovers,
    $navTrigger,
    $mobileNav,
    planTypes = {};

/**
 * Functions
 */

/**
 * Returns the width of the window, including fractional pixels from different dpi
 */
function windowWidth () {
    return window.innerWidth + $body[0].getBoundingClientRect().width % 1;
}

/**
 * Throttle function from Underscore https://underscorejs.org/docs/modules/throttle.html
 * Because Foundation's implementation doesn't include optional leading and trailing calls
 */  
function throttle (func, wait, options) {
    var timeout, context, args, result;
    var previous = 0;
    if (!options) options = {};

    var later = function () {
        previous = options.leading === false ? 0 : new Date().getTime();
        timeout = null;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
    };

    var throttled = function () {
        var _now = new Date().getTime();
        if (!previous && options.leading === false) previous = _now;
        var remaining = wait - (_now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = _now;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    };

    throttled.cancel = function () {
        clearTimeout(timeout);
        previous = 0;
        timeout = context = args = null;
    };

    return throttled;
}

/**
 * Sets the state of the mobile nav
 */
function setMobileMenu(open) {
    let ariaExpanded = open ? 'true' : 'false',
        ariaLabel = open ? 'Close Menu' : 'Menu',
        delayToggleNonNav = open ? delayDefault : delayInstant,
        setNonNavClass = open ? $nonNavItems.addClass : $nonNavItems.removeClass,
        setTriggerClass = open ? $navTrigger.addClass : $navTrigger.removeClass,
        setBodyClass = open ? $body.addClass : $body.removeClass,
        setHeaderEntryState = open ? $headerEntry.slideDown : $headerEntry.slideUp;

    setHeaderEntryState.call($headerEntry, delayDefault);
    setBodyClass.call($body, 'fixed');
    setTriggerClass.call($navTrigger, 'nav-trigger--active');
    setTimeout(function () {
        setNonNavClass.call($nonNavItems, 'show-hide-item');
    }, delayToggleNonNav);

    $navTrigger.attr('aria-expanded', ariaExpanded);
    setTimeout(function () {
        $navTrigger.attr('aria-label', ariaLabel);
    }, delayFast);

    if (open) {
        //$mobileNav.find(focusableSelector).first().focus();
    } else {
        closeDropdowns();
    }
}

/**
 * Sets the dropdown nav's state
 */
function setDropdown($dropdown, open, focus) {
    if (!$dropdown?.hasClass('has-dd')) {
        return;
    }

    let ariaExpanded = open ? 'true' : 'false',
        $ddSiblings = $dropdown.siblings('.has-dd'),
        $ddButton = $dropdown.find('span button[aria-controls], span a[aria-controls]'),
        ariaControls = $ddButton.attr('aria-controls'),
        $ddLinks = $dropdown.find(`ul#${ariaControls}`),
        setDropdownClass = open ? $dropdown.addClass : $dropdown.removeClass,
        setDropdownState = open ? $ddLinks.slideDown : $ddLinks.slideUp;
    setDropdownState.call($ddLinks, delayDefault);
    setDropdownClass.call($dropdown, 'open');
    $ddButton.attr('aria-expanded', ariaExpanded);

    if (open) {
        $ddSiblings.each(function (i, ddSibling) {
            setDropdown($(ddSibling));
        });
        if (windowWidth() < 1200 || focus) {
            setTimeout(function () {
                $ddLinks.find(focusableSelector).first().focus();
            }, delayFast);
        }
    }
}

/**
 * Closes any open dropdown navs
 */
function closeDropdowns() {
    let $openDropdowns = $('.m-nav-secondary li.has-dd.open');
    $openDropdowns.each(function (i, dd) {
        setDropdown($(dd));
    });
}

/**
 * Main
 */
$(() => {
    // Initialize Foundation
    $(document).foundation();

    // Select elements
    $nonNavItems = $(nonNavSelector);
    $headerEntry = $('.m-header-entry');
    $navSecondary = $('.m-nav-secondary');

    $(".ft-hide-btn").on('click', function () {
        $(".floating-toolbar-inner").toggle("slide");
        $(".floating-toolbar .fluid").toggleClass("hide-ft-btn");
        var $icon = $(".floating-toolbar .fa");
        $icon.toggleClass("fa-long-arrow-left")
            .toggleClass("fa-long-arrow-right");
        if ($icon.hasClass('fa-long-arrow-right')) {
            $icon.attr('alt', 'Show Toolbar');
            $icon.attr('aria-hidden', 'false');
        } else {
            $icon.removeAttr('alt');
            $icon.attr('aria-hidden', 'true');
        }
    });
    
    $navTrigger = $('.nav-trigger');
    $mobileNav = $navSecondary.clone()
        .addClass('js-clone')
        .attr('id', 'mobile-nav')
        .attr('aria-expanded', 'false')
        .prependTo($headerEntry);
    $mobileNav.find('.fa-caret-down').removeClass('fa-caret-down').addClass('fa-caret-right');
    // NOTE: This must be done after the mobile nav is cloned
    $dropdownButtons = $('li.has-dd button[aria-controls], li.has-dd a[aria-controls]');
    $dropdownHovers = $('.m-nav-secondary:not(.js-clone) li.has-dd');

    // Remove mobile elements from desktop clone
    $navSecondary.find('.hide-for-large').remove();

    // Configure mobile menu
    $mobileNav.find('li.has-dd').each(function (i, li) {
        let $li = $(li),
            $button = $(li).find('[aria-controls]').first(),
            $dd = $li.find('ul').first(),
            controls = $button.attr('aria-controls') + '-mobile';
        $button.attr('aria-controls', controls);
        $dd.attr('id', $dd.attr('id') + '-mobile');
    });

    // When the menu button is clicked, toggle the mobile menu
    $navTrigger.on('click', throttle(function () {
        setMobileMenu($navTrigger.attr('aria-expanded').toLowerCase() === 'false');
    }, delayDefault, { trailing: false }));

    // When the window resizes, close the mobile menu
    $win.on('resize', throttle(function () {
        setMobileMenu();
    }, delayDefault, { trailing: false }));

    // Return focus to the menu button after tabbing past the last mobile menu item
    $headerEntry.find(focusableSelector).last().on('keydown', throttle(function (e) {
        let tabPressed = e.key.toLowerCase() === 'tab' || e.keyCode === 9,
            shiftPressed = e.shiftKey;
        if (tabPressed && !shiftPressed) {
            $navTrigger.focus();
            if (document.activeElement === $navTrigger[0]) {
                e.preventDefault();
            }
        }
    }, delayDefault));

    // When the window scrolls, close the dropdowns
    $win.on('scroll', throttle(function () {
        closeDropdowns();
    }, delayDefault, { trailing: false }));

    // When a dropdown nav is clicked, toggle its menu
    // NOTE: This event must use mousedown instead of click.  This is because
    //       the document's focus is changed within these functions and mousedown
    //       runs before focus, which runs before click.
    $dropdownButtons.on('mouseup', throttle(function (e) {
        let $this = $(this),
            $dropdown = $this.closest('li.has-dd'),
            open = !$dropdown?.hasClass('open');
        
        if ($this.prop('tagName') === 'A' && windowWidth() < 1200) {
            setDropdown($dropdown, open);
        }

        if ($this.attr('data-label') === 'Back') {
            setDropdown($dropdown);
        }
    }, delayDefault, { trailing: false }));

    // Mousedown can't prevent click
    $dropdownButtons.on('click', throttle(function (e) {
        let $this = $(this);
        if ($this.prop('tagName') === 'A' && windowWidth() < 1200) {
            e.preventDefault();
        }
    }, delayDefault, { trailing: false }));

    // Handle dropdown button keypresses
    $dropdownButtons.on('keydown', throttle(function (e) {
        let key = e.key.toLowerCase(),
            code = e.keyCode,
            $dd = $(this).closest('li.has-dd');
        switch (false) {
            case key === 'tab' || code === 9:
                e.preventDefault();
        }
        switch (true) {
            case key === 'escape' || code === 27:
                if ($dd.hasClass('open')) {
                    setDropdown($dd);
                } else {
                    setMobileMenu();
                    $navTrigger.focus();
                }
                break;
            // NOTE: This is needed because mouse events are registered with mousedown
            //       which doesn't account for pressing the enter or space keys.
            case key === 'enter' || code === 13:
            case key === 'space' || code === 32:
                setDropdown($dd, !$dd.hasClass('open'), true);
                break;
            case key === 'end' || code === 35:
                $dd.parent().children().last().find(focusableSelector)?.first().focus();
                break;
            case key === 'home' || code === 36:
                $dd.parent().children().first().find(focusableSelector)?.first().focus();
                break;
            case key === 'arrowleft' || code === 37:
            case key === 'arrowup' || code === 38:
                $dd.prev()?.find(focusableSelector)?.first().focus();
                break;
            case key === 'arrowright' || code === 39:
            case key === 'arrowdown' || code === 40:
                if ($dd.hasClass('open')) {
                    $dd.children('ul')?.find(focusableSelector)?.first().focus();
                } else {
                    $dd.next()?.find(focusableSelector)?.first().focus();
                }
        }
    }, delayFast));

    $dropdownButtons.on('keyup', throttle(function (e) {
        let key = e.key.toLowerCase(),
            code = e.keyCode,
            $dd = $(this).closest('li.has-dd');
        switch (true) {
            case key === 'tab' || code === 9:
                if (windowWidth() > 1200) {
                    setDropdown($dd, !$dd.hasClass('open'), true);
                }
        }
    }, delayFast));

    // When a dropdown nav is focused on, close the previous menu
    $dropdownButtons.on('focus', function (e) {
        let $dd = $(this).closest('li.has-dd'),
            $prevDD = $(e.relatedTarget).closest('li.has-dd');

        if ($dd[0] !== $prevDD[0]) {
            setDropdown($prevDD);
        }
    });

    // When a dropdown link is hovered, open its menu
    $dropdownHovers.on('mouseenter', throttle(function () {
        setDropdown($(this), true);
    }, delayFast, { leading: true, trailing: false }));

    // When a dropdown link is unhovered, open its menu
    $dropdownHovers.on('mouseleave', throttle(function () {
        setDropdown($(this), false);
    }, delayFast, { leading: true, trailing: false }));

    // When a dropdown nav loses focus, close its menu
    $('li.has-dd').each(function (i, dd) {
        let $dd = $(dd);
        $dd.find(focusableSelector).on('blur', function (e) {
            if (!$dd.find(e.relatedTarget).length) {
                if (!$(e.relatedTarget).attr('aria-controls')) {
                    setDropdown($dd);
                }
            }
        });
    });

    // Handle link keypresses
    $('.m-nav-secondary a, .m-nav-secondary [data-label="Back"]').on('keydown', throttle(function (e) {
        let key = e.key.toLowerCase(),
            code = e.keyCode,
            $dd = $(this).closest('li.has-dd');
        switch (false) {
            case key === 'tab' || code === 9:
                e.preventDefault();
        }
        switch (true) {
            case key === 'escape' || code === 27:
                if ($dd.length) {
                    setDropdown($dd);
                    $dd.find(focusableSelector)?.first().focus();
                } else {
                    setMobileMenu();
                    $navTrigger.focus();
                }
                break;
            case key === 'enter' || code === 13:
            case key === 'space' || code === 32:
                $('.m-nav-secondary a').removeAttr('aria-current');
                $(this).attr('aria-current', 'page');
                this.click();
                break;
            case key === 'end' || code === 35:
                if ($dd.length) {
                    $dd.children('ul')?.find(focusableSelector)?.last().focus();
                } else {
                    $(this).closest('ul')?.find(focusableSelector)?.last().focus();
                }
                break;
            case key === 'home' || code === 36:
                if ($dd.length) {
                    $dd.children('ul')?.find(focusableSelector)?.first().focus();
                } else {
                    $(this).closest('ul')?.find(focusableSelector)?.first().focus();
                }
                break;
            case key === 'arrowleft' || code === 37:
            case key === 'arrowup' || code === 38:
                let $prev = $(this).closest('li')?.prev();
                if ($dd.length && !$prev?.length) {
                    $dd.find(focusableSelector)?.first().focus();
                } else {
                    $prev.find(focusableSelector)?.first().focus();
                }
                break;
            case key === 'arrowright' || code === 39:
            case key === 'arrowdown' || code === 40:
                let $next = $(this).closest('li')?.next();
                if ($dd.length && !$next?.length) {
                    $dd.next()?.find(focusableSelector)?.first().focus();
                } else {
                    $next.find(focusableSelector)?.first().focus();
                }
        }
    }, delayFast));

    // Homepage alert mobile 
    $('.caret-button').on('click', function (e) {
        let $caretButton = $(this),
            $caret = $caretButton.children().first();
        $('#alerts-container').slideToggle();
        if ($caret.hasClass('fa-caret-down')) {
            $caret.removeClass('fa-caret-down');
            $caret.addClass('fa-caret-up');
            $caretButton.attr('aria-expanded', 'true');
            $caretButton.attr('aria-label', 'Close alerts');
        } else {
            $caret.removeClass('fa-caret-up');
            $caret.addClass('fa-caret-down');
            $caretButton.attr('aria-expanded', 'false');
            $caretButton.attr('aria-label', 'Open alerts');
        }
    });

    $('.hero-video').magnificPopup({
        type: 'inline',
        mainClass: 'mfp-fade',
        preloader: true,
        midClick: true
    });

    $body.on('change', '.url-list', function () {
        var $selectedUrl = $('.url-list').val(),
            $selectorButton = $('.url-selector-btn');

        if (!$selectedUrl) {
            $selectorButton.attr('disabled', false);
        } else {
            $selectorButton.attr('disabled', 'disabled');
        }
    });

    $('.url-selector-btn').click(function (e) {
        var selectedUrl = $('.url-list').val();

        if ($(this).hasClass('a-external-link')) {
            if (!selectedUrl) {
                e.preventDefault();
            } else {
                window.open(selectedUrl, '_blank');
            }
        } else {
            if (!selectedUrl) {
                e.preventDefault();
            } else {
                window.location = selectedUrl;
            }
        }
    });

    // Add icon to external links, if they don't already have one
    $('.a-external-link, a[target=_blank]').each(function (i, link) {
        let $link = $(link),
            $icon = $link.find('i.fa');
        if (!$icon.length) {
            $link.append($('<i class="fa fa-external-link" aria-label="opens in new tab"></i>'));
        }
    });

    // Add required / aria-required to inputs
    $('.form-control').prop('required', true).attr('aria-required', 'true');

    // Add autocomplete info to inputs
    $('.form-control[name$="Name.Value"]').prop('autocomplete', 'name');
    $('.form-control[name$="Address.Value"]').prop('autocomplete', 'street-address');
    $('.form-control[name$="Phone.PhoneNumber"]').prop('autocomplete', 'tel');
    $('.form-control[name$="Email.Email"]').prop('autocomplete', 'email');

    // Add button class to submit
    $('input[type=submit]').addClass('a-btn-main');

    // Associate inputs to their error messages
    $('.form-control').each((index, value) => {
        let $value = $(value),
            id = $value.prop('id'),
            $errors = $value.closest('.editing-form-control-nested-control').children('.field-validation-valid'),
            describedBy = '';

        $errors.each((index2, value2) => {
            let id2 = id + index2;
            $(value2).prop('id', id2);
            describedBy += ' ' + id2;
        });

        $value.attr('aria-describedby', describedBy);
    });

    // Initialize Swiper
    // NOTE: This might only work with 1 swiper on the page
    const sliderChildren = $('.swiper-wrapper').children().length;
    const slider = new Swiper('.windsor-gallery-slider', {
        modules: [Controller, Navigation],
        slidesPerView: 1,
        centeredSlides: true,
        loop: true,
        loopedSlides: sliderChildren,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    const thumbs = new Swiper('.windsor-gallery-thumbs', {
        modules: [Controller, Navigation],
        slidesPerView: 'auto',
        spaceBetween: 10,
        centeredSlides: true,
        loop: true,
        slideToClickedSlide: true,
        controller: {
            by: 'slide',
            control: slider,
        }
    });

    slider.controller.control = thumbs;
    thumbs.controller.control = slider;

    // Initialize the floor plan widget
    // NOTE: This only works with 1 floor plan widget on the page
    let $planOptions = $('.floor-plans #plan-title option');
    $planOptions.each((index, option) => {
        let $option = $(option),
            type = $option.data('type');
        if (!planTypes[type]) {
            planTypes[type] = [];
        }
        planTypes[type].push($option);
        $option.remove();
    });

    $('.floor-plans #plan-title').off('change').on('change', throttle(function () {
        $('.floor-plans img').attr('src', $(this).val());
        var cardText = $(this.options[this.selectedIndex]).data('card');
        if (cardText) {
            var cardItems = cardText.split('\n');
            if (cardItems.length === 5) {
                $('#card-apartment h3').html(cardItems[0]);
                $('#card-apartment p').html(cardItems[1]);
                $('#video-apartment iframe').attr('src', cardItems[2]);
                $('#video-apartment a').attr('href', cardItems[3]);
                $('#card-apartment .m-card__image').css('background-image', 'url("' + cardItems[4] + '")');
                $('#card-apartment').removeClass('hide');
            } else {
                $('#card-apartment').addClass('hide');
            }
        }
    }));
    
    $('.floor-plans #plan-type').off('change').on('change', throttle(function () {
        $('.floor-plans #plan-title option').remove();
        planTypes[$(this).val()].forEach(option => {
            $('.floor-plans #plan-title').append(option);
        });
        $('.floor-plans #plan-title').val($('.floor-plans #plan-title option').first().val()).trigger('change');
    }));

    $('.floor-plans #plan-type').trigger('change');
});

/*
*   This content is licensed according to the W3C Software License at
*   https://www.w3.org/Consortium/Legal/2015/copyright-software-and-document
*/
(function () {
    var tablist = document.querySelectorAll('[role="tablist"]');

    if (!tablist.length) {
        return false;
    }

    tablist = tablist[0];
    var tabs;
    var panels;
    var delay = determineDelay();

    generateArrays();

    function generateArrays() {
        tabs = document.querySelectorAll('[role="tab"]');
        panels = document.querySelectorAll('[role="tabpanel"]');
    };

    // For easy reference
    var keys = {
        end: 35,
        home: 36,
        left: 37,
        up: 38,
        right: 39,
        down: 40,
        delete: 46
    };

    // Add or substract depending on key pressed
    var direction = {
        37: -1,
        38: -1,
        39: 1,
        40: 1
    };

    // Bind listeners
    for (var i = 0; i < tabs.length; ++i) {
        addListeners(i);
    };

    function addListeners(index) {
        tabs[index].addEventListener('click', clickEventListener);
        tabs[index].addEventListener('keydown', keydownEventListener);
        tabs[index].addEventListener('keyup', keyupEventListener);

        // Build an array with all tabs (<button>s) in it
        tabs[index].index = index;
    };

    // When a tab is clicked, activateTab is fired to activate it
    function clickEventListener(event) {
        var tab = event.target;
        activateTab(tab, false);
    };

    // Handle keydown on tabs
    function keydownEventListener(event) {
        var key = event.keyCode;

        switch (key) {
            case keys.end:
                event.preventDefault();
                // Activate last tab
                activateTab(tabs[tabs.length - 1]);
                break;
            case keys.home:
                event.preventDefault();
                // Activate first tab
                activateTab(tabs[0]);
                break;

            // Up and down are in keydown
            // because we need to prevent page scroll >:)
            case keys.up:
            case keys.down:
                determineOrientation(event);
                break;
        };
    };

    // Handle keyup on tabs
    function keyupEventListener(event) {
        var key = event.keyCode;

        switch (key) {
            case keys.left:
            case keys.right:
                determineOrientation(event);
                break;
            case keys.delete:
                determineDeletable(event);
                break;
        };
    };

    // When a tablistâ€™s aria-orientation is set to vertical,
    // only up and down arrow should function.
    // In all other cases only left and right arrow function.
    function determineOrientation(event) {
        var key = event.keyCode;
        var vertical = tablist.getAttribute('aria-orientation') == 'vertical';
        var proceed = false;

        if (vertical) {
            if (key === keys.up || key === keys.down) {
                event.preventDefault();
                proceed = true;
            };
        }
        else {
            if (key === keys.left || key === keys.right) {
                proceed = true;
            };
        };

        if (proceed) {
            switchTabOnArrowPress(event);
        };
    };

    // Either focus the next, previous, first, or last tab
    // depening on key pressed
    function switchTabOnArrowPress(event) {
        var pressed = event.keyCode;

        for (var x = 0; x < tabs.length; x++) {
            tabs[x].addEventListener('focus', focusEventHandler);
        };

        if (direction[pressed]) {
            var target = event.target;
            if (target.index !== undefined) {
                if (tabs[target.index + direction[pressed]]) {
                    tabs[target.index + direction[pressed]].focus();
                }
                else if (pressed === keys.left || pressed === keys.up) {
                    focusLastTab();
                }
                else if (pressed === keys.right || pressed == keys.down) {
                    focusFirstTab();
                };
            };
        };
    };

    // Activates any given tab panel
    function activateTab(tab, setFocus) {
        setFocus = setFocus || true;
        // Deactivate all other tabs
        deactivateTabs();

        // Remove tabindex attribute
        tab.removeAttribute('tabindex');

        // Set the tab as selected
        tab.setAttribute('aria-selected', 'true');

        // Get the value of aria-controls (which is an ID)
        var controls = tab.getAttribute('aria-controls');

        // Remove hidden attribute from tab panel to make it visible
        document.getElementById(controls).removeAttribute('hidden');

        // Set focus when required
        if (setFocus) {
            tab.focus();
        };
    };

    // Deactivate all tabs and tab panels
    function deactivateTabs() {
        for (var t = 0; t < tabs.length; t++) {
            tabs[t].setAttribute('tabindex', '-1');
            tabs[t].setAttribute('aria-selected', 'false');
            tabs[t].removeEventListener('focus', focusEventHandler);
        };

        for (var p = 0; p < panels.length; p++) {
            panels[p].setAttribute('hidden', 'hidden');
        };
    };

    // Make a guess
    function focusFirstTab() {
        tabs[0].focus();
    };

    // Make a guess
    function focusLastTab() {
        tabs[tabs.length - 1].focus();
    };

    // Detect if a tab is deletable
    function determineDeletable(event) {
        var target = event.target;

        if (target.getAttribute('data-deletable') !== null) {
            // Delete target tab
            deleteTab(event, target);

            // Update arrays related to tabs widget
            generateArrays();

            // Activate the closest tab to the one that was just deleted
            if (target.index - 1 < 0) {
                activateTab(tabs[0]);
            }
            else {
                activateTab(tabs[target.index - 1]);
            };
        };
    };

    // Deletes a tab and its panel
    function deleteTab(event) {
        var target = event.target;
        var panel = document.getElementById(target.getAttribute('aria-controls'));

        target.parentElement.removeChild(target);
        panel.parentElement.removeChild(panel);
    };

    // Determine whether there should be a delay
    // when user navigates with the arrow keys
    function determineDelay() {
        var hasDelay = tablist.hasAttribute('data-delay');
        var delay = 0;

        if (hasDelay) {
            var delayValue = tablist.getAttribute('data-delay');
            if (delayValue) {
                delay = delayValue;
            }
            else {
                // If no value is specified, default to 300ms
                delay = 300;
            };
        };

        return delay;
    };

    //
    function focusEventHandler(event) {
        var target = event.target;

        setTimeout(checkTabFocus, delay, target);
    };

    // Only activate tab on focus if it still has focus after the delay
    function checkTabFocus(target) {
        var focused = document.activeElement;

        if (target === focused) {
            activateTab(target, false);
        };
    };
}());